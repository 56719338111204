import React from "react";
import styled from "styled-components";

import { gtMDMediaQuery } from "../theme/global";
import { theme } from "../theme/theme";
import { HalfCenteredRow } from "./common/layout";

const Title = styled.h2`
  position: relative;
  display: inline-block;

  &::before,
  &::after {
    content: "";
    position: absolute;
    display: block;
    background-size: 100%;
    background-position: 50%;
    background-repeat: no-repeat;
  }

  &::before {
    top: 0.41666666666em; //30px/72
    right: 100%;
    height: 2.08333333333em; //150px;
    width: 1.52777777778em; //110px;
    background-image: url("data:image/svg+xml;charset=UTF-8,%3csvg xmlns='http://www.w3.org/2000/svg' x='0px' y='0px' viewBox='0 0 110 150'%3e%3cpath fill='${theme
      .palette.primary
      .basic}' d='M66.9,108.6l38.3-17.4c1.8-0.8,3.2,1.6,2,3.4c-14.9,20.4-39.5,44.6-61.3,54.2c-1.1,0.5-2.2-0.4-2.2-1.8 l-0.5-26.6L4.8,137.7c-1.8,0.8-3.2-1.6-2-3.4c14.9-20.4,39.5-44.6,61.3-54.2c1.1-0.5,2.2,0.4,2.2,1.8L66.9,108.6z' /%3e%3cpath fill='${theme
      .palette.primary
      .basic}' d='M34.9,17.8L6.6,14.8c-1.3-0.1-1.3-2,0-2.7C22.2,5.4,44.7-0.3,60.7,1.6c0.8,0.1,1.2,1,0.7,1.8L52.7,19l28.2,2.9 c1.3,0.1,1.3,2,0,2.7c-15.6,6.8-38.1,12.5-54.1,10.6c-0.8-0.1-1.2-1-0.7-1.8L34.9,17.8z'/%3e%3c/svg%3e");

    ${gtMDMediaQuery} {
      margin-right: 0.5em;
    }
  }
`;

const Wrapper = styled.section`
  text-align: center;
  padding: 12.5em 0 3.125em;

  //TO BYPASS STYLED COMPONENT BUG == ::before::after
  ${Title}::after {
    top: -1.38888888889em; //-100px;
    left: 100%;
    height: 2.22222222222em; //160px;
    width: 1.11111111111em; //80px;
    background-image: url("data:image/svg+xml;charset=UTF-8,%3csvg xmlns='http://www.w3.org/2000/svg' x='0px' y='0px' width='80' height='160' viewBox='0 0 80 160'%3e%3cpath fill='${theme
      .palette.primary
      .basic}' d='M50.1,35.5L79,4.9c1.3-1.4-0.5-3.6-2.6-3c-24.1,7.4-55,22.9-71.1,40.5c-0.8,0.9-0.3,2.2,0.9,2.7l25,9.1 L2.5,84.8c-1.3,1.4,0.5,3.6,2.6,3c24.1-7.4,55-22.9,71.1-40.5c0.8-0.9,0.3-2.2-0.9-2.7L50.1,35.5z'/%3e%3cpath fill='${theme
      .palette.primary
      .basic}' d='M28.4,144.1l-27.1,8.4c-1.3,0.4-0.9,2.3,0.5,2.6c16.6,3.6,39.9,4.7,55.2-0.3c0.8-0.3,0.9-1.2,0.3-1.9 l-11.7-13.6l27.1-8.4c1.3-0.4,0.9-2.3-0.5-2.6c-16.6-3.6-39.9-4.7-55.2,0.3c-0.8,0.3-0.9,1.2-0.3,1.9L28.4,144.1z'/%3e%3c/svg%3e");

    ${gtMDMediaQuery} {
      margin-left: 0.5em;
    }
  }
`;

type PropsModel = {
  textHTML: string;
};

export const Overstatement = ({ textHTML }: PropsModel) => {
  return (
    <Wrapper>
      <HalfCenteredRow>
        <Title
          className="big-heading"
          dangerouslySetInnerHTML={{
            __html: textHTML,
          }}
        />
      </HalfCenteredRow>
    </Wrapper>
  );
};
