import React from "react";
import { BLOCKS } from "@contentful/rich-text-types";
import { documentToReactComponents } from "@contentful/rich-text-react-renderer";
import { InView } from "react-intersection-observer";
import styled from "styled-components";
import { useIntl } from "gatsby-plugin-intl";

import { detectOs, OS } from "../utils/detect-os";
import { LocalizedAsset, localizeAsset, Locale } from "../utils/region";
import { CenteredRowWithColumns, Column } from "../components/common/layout";
import { GooglePlayButton } from "../components/common/googleplay-button";
import { AppleStoreButton } from "../components/common/applestore-button";
import { BrowserExtensionButton } from "../components/common/browser-extension-button";
import { gtMDMediaQuery, ltMDMediaQuery } from "../theme/global";
import { headerGreaterThanMDMediaQuery } from "./header";

import FlashesSrc from "../images/flashes.svg";
import FlashesForNarrowerScreensSrc from "../images/flashes-for-narrower-screens.svg";
import FirstSmartphoneShadowSvg from "../images/first-smartphone-shadow.inline.svg";

const SmartphoneSrc: LocalizedAsset = {
  fr: require("../images/smartphone/joko-app-screenshot-home-fr.png"),
  en: require("../images/smartphone/joko-app-screenshot-home-en.png"),
};
const Smartphone2xSrc: LocalizedAsset = {
  fr: require("../images/smartphone/joko-app-screenshot-home-fr@2x.png"),
  en: require("../images/smartphone/joko-app-screenshot-home-en@2x.png"),
};
const SmartphoneWebPSrc: LocalizedAsset = {
  fr: require("../images/smartphone/joko-app-screenshot-home-fr.webp"),
  en: require("../images/smartphone/joko-app-screenshot-home-en.webp"),
};
const SmartphoneWebP2xSrc: LocalizedAsset = {
  fr: require("../images/smartphone/joko-app-screenshot-home-fr@2x.webp"),
  en: require("../images/smartphone/joko-app-screenshot-home-en@2x.webp"),
};

const Wrapper = styled.section`
  position: relative;
  display: flex;
  flex-direction: row;
  align-items: center;
  box-sizing: border-box;
  min-height: 100vh;
  padding: 9.375em 0 3.125em;
  margin-top: -6.25em; // height of top bar + margin

  ${headerGreaterThanMDMediaQuery} {
    padding-top: 10.375em;
    margin-top: -7.25em;
  }

  &::before {
    content: "";
    z-index: -1;
    position: absolute;
    top: 0;
    left: 0;
    display: block;
    height: 200%;
    width: 100%;
    background-image: url(${FlashesForNarrowerScreensSrc});
    background-repeat: no-repeat;
    background-position: 50% 50%;
  }

  ${ltMDMediaQuery} {
    text-align: center;
  }

  ${gtMDMediaQuery} {
    &::before {
      height: calc(100% + 6em);
      background-image: url(${FlashesSrc});
      background-size: 1600px 100%;
      background-position: top 0px right 0px;
    }
  }
`;

const InnerWrapper = styled.div`
  width: 100%;
`;

const Tagline = styled.h2`
  margin-bottom: ${50 / 72}em;

  ${gtMDMediaQuery} {
    width: 130%;
  }
`;

const ButtonsList = styled.ul`
  margin: ${2.875 - 0.8125}em -0.8125em -0.8125em;
`;

const ButtonsListItem = styled.li`
  display: inline-block;
  vertical-align: middle;
  margin: 0.8125em;
`;

const TextsColumn = styled(Column)`
  ${gtMDMediaQuery} {
    display: flex;
    flex-diretion: column;
    align-items: center;
  }
`;

const SmartphoneColumn = styled(Column)`
  text-align: center;

  ${ltMDMediaQuery} {
    margin-top: 3.125em;
    margin-bottom: -18.75em; // 300
    padding-right: 3.125em;
    padding-left: 3.125em;
  }

  ${gtMDMediaQuery} {
    margin-bottom: -15.625em; // 250
  }
`;

const Smartphone = styled.img`
  z-index: 1;
  position: relative;
  height: auto;
  width: 31.25em;
  max-width: 100%;
  overflow: visible;
  filter: url(#drop-shadow);

  @media screen and (min-width: 79.6875em) {
    transform: rotate(5deg);
  }
`;

const options = {
  renderNode: {
    [BLOCKS.PARAGRAPH]: (_node, children) => (
      <p className="emphasized-text">{children}</p>
    ),
  },
};

type PropsModel = {
  taglineHtml: string;
  indicationRaw: string;
};

export const Intro = ({ taglineHtml, indicationRaw }: PropsModel) => {
  const os: OS = detectOs();
  const intl = useIntl();
  const locale = intl.locale as Locale;
  return (
    <InView
      onChange={(inView, _entry) => {
        window.dispatchEvent(
          new CustomEvent("IntroInView", { detail: { inView: inView } })
        );
      }}
    >
      <FirstSmartphoneShadowSvg />
      <Wrapper>
        <InnerWrapper>
          <CenteredRowWithColumns
            breakpoints={[{ value: 768, portion: 0.5, gutter: 160 }]}
          >
            <TextsColumn>
              <div>
                <Tagline
                  className="big-heading"
                  dangerouslySetInnerHTML={{
                    __html: taglineHtml,
                  }}
                />
                {indicationRaw
                  ? documentToReactComponents(
                      JSON.parse(indicationRaw),
                      options
                    )
                  : ""}
                <ButtonsList>
                  {os === OS.Desktop || os === OS.Android ? (
                    <ButtonsListItem>
                      <GooglePlayButton />
                    </ButtonsListItem>
                  ) : null}

                  {os === OS.Desktop || os === OS.IOs ? (
                    <ButtonsListItem>
                      <AppleStoreButton />
                    </ButtonsListItem>
                  ) : null}

                  {os !== OS.Android && os !== OS.IOs ? (
                    <ButtonsListItem>
                      <BrowserExtensionButton />
                    </ButtonsListItem>
                  ) : null}
                </ButtonsList>
              </div>
            </TextsColumn>
            <SmartphoneColumn>
              <picture>
                <source
                  srcSet={`${localizeAsset(
                    locale,
                    SmartphoneWebPSrc
                  )} 500w, ${localizeAsset(locale, SmartphoneWebP2xSrc)} 1000w`}
                  type="image/webp"
                />
                <source
                  srcSet={`${localizeAsset(
                    locale,
                    SmartphoneSrc
                  )} 500w, ${localizeAsset(locale, Smartphone2xSrc)} 1000w`}
                  type="image/png"
                />
                <Smartphone
                  src={localizeAsset(locale, SmartphoneSrc)}
                  width="500"
                  alt={intl.formatMessage({ id: "home.introImageAltText" })}
                />
              </picture>
            </SmartphoneColumn>
          </CenteredRowWithColumns>
        </InnerWrapper>
      </Wrapper>
    </InView>
  );
};
