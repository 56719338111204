import { ContentModel } from "../models/content.model";
import { shuffleContents } from "./shuffle-contents";

export async function fetchVisibleContents<T>(
  path: string,
  randomOrder: boolean = false
): Promise<T[]> {
  const response = await fetch(process.env.S3_URL + path, {
    method: "GET",
  });
  const responseJson: ContentModel<T>[] = await response.json();
  // responseJson is mutated if randomOrder
  if (randomOrder) shuffleContents<T>(responseJson);
  return responseJson
    .filter((content: ContentModel<T>) => content.visible === true)
    .map((content: ContentModel<T>) => content.data);
}
