import React from "react";
import styled from "styled-components";
import { useIntl } from "gatsby-plugin-intl";
import { BLOCKS } from "@contentful/rich-text-types";
import { documentToReactComponents } from "@contentful/rich-text-react-renderer";

import { theme } from "../theme/theme";
import { PresentationArgumentModel } from "../models/presentation-argument.model";
import { CenteredRow, HalfCenteredRow } from "./common/layout";
import { headerGreaterThanMDMediaQuery } from "./header";
import { LocalizedAsset, localizeAsset, Locale } from "../utils/region";

const Smartphone1Src: LocalizedAsset = {
  fr: require("../images/smartphone/joko-app-screenshot-home-1-fr.png"),
  en: require("../images/smartphone/joko-app-screenshot-home-1-en.png"),
};
const Smartphone12xSrc: LocalizedAsset = {
  fr: require("../images/smartphone/joko-app-screenshot-home-1-fr@2x.png"),
  en: require("../images/smartphone/joko-app-screenshot-home-1-en@2x.png"),
};
const Smartphone1WebPSrc: LocalizedAsset = {
  fr: require("../images/smartphone/joko-app-screenshot-home-1-fr.webp"),
  en: require("../images/smartphone/joko-app-screenshot-home-1-en.webp"),
};
const Smartphone1WebP2xSrc: LocalizedAsset = {
  fr: require("../images/smartphone/joko-app-screenshot-home-1-fr@2x.webp"),
  en: require("../images/smartphone/joko-app-screenshot-home-1-en@2x.webp"),
};
const Smartphone2Src: LocalizedAsset = {
  fr: require("../images/smartphone/joko-app-screenshot-home-2-fr.png"),
  en: require("../images/smartphone/joko-app-screenshot-home-2-en.png"),
};
const Smartphone22xSrc: LocalizedAsset = {
  fr: require("../images/smartphone/joko-app-screenshot-home-2-fr@2x.png"),
  en: require("../images/smartphone/joko-app-screenshot-home-2-en@2x.png"),
};
const Smartphone2WebPSrc: LocalizedAsset = {
  fr: require("../images/smartphone/joko-app-screenshot-home-2-fr.webp"),
  en: require("../images/smartphone/joko-app-screenshot-home-2-en.webp"),
};
const Smartphone2WebP2xSrc: LocalizedAsset = {
  fr: require("../images/smartphone/joko-app-screenshot-home-2-fr@2x.webp"),
  en: require("../images/smartphone/joko-app-screenshot-home-2-en@2x.webp"),
};
const Smartphone3Src: LocalizedAsset = {
  fr: require("../images/smartphone/joko-app-screenshot-home-3-fr.png"),
  en: require("../images/smartphone/joko-app-screenshot-home-3-en.png"),
};
const Smartphone32xSrc: LocalizedAsset = {
  fr: require("../images/smartphone/joko-app-screenshot-home-3-fr@2x.png"),
  en: require("../images/smartphone/joko-app-screenshot-home-3-en@2x.png"),
};
const Smartphone3WebPSrc: LocalizedAsset = {
  fr: require("../images/smartphone/joko-app-screenshot-home-3-fr.webp"),
  en: require("../images/smartphone/joko-app-screenshot-home-3-en.webp"),
};
const Smartphone3WebP2xSrc: LocalizedAsset = {
  fr: require("../images/smartphone/joko-app-screenshot-home-3-fr@2x.webp"),
  en: require("../images/smartphone/joko-app-screenshot-home-3-en@2x.webp"),
};
const Smartphone4Src: LocalizedAsset = {
  fr: require("../images/smartphone/joko-app-screenshot-home-4-fr.png"),
  en: require("../images/smartphone/joko-app-screenshot-home-4-en.png"),
};
const Smartphone42xSrc: LocalizedAsset = {
  fr: require("../images/smartphone/joko-app-screenshot-home-4-fr@2x.png"),
  en: require("../images/smartphone/joko-app-screenshot-home-4-en@2x.png"),
};
const Smartphone4WebPSrc: LocalizedAsset = {
  fr: require("../images/smartphone/joko-app-screenshot-home-4-fr.webp"),
  en: require("../images/smartphone/joko-app-screenshot-home-4-en.webp"),
};
const Smartphone4WebP2xSrc: LocalizedAsset = {
  fr: require("../images/smartphone/joko-app-screenshot-home-4-fr@2x.webp"),
  en: require("../images/smartphone/joko-app-screenshot-home-4-en@2x.webp"),
};
const Smartphone5Src: LocalizedAsset = {
  fr: require("../images/smartphone/joko-app-screenshot-home-5-fr.png"),
  en: require("../images/smartphone/joko-app-screenshot-home-5-en.png"),
};
const Smartphone52xSrc: LocalizedAsset = {
  fr: require("../images/smartphone/joko-app-screenshot-home-5-fr@2x.png"),
  en: require("../images/smartphone/joko-app-screenshot-home-5-en@2x.png"),
};
const Smartphone5WebPSrc: LocalizedAsset = {
  fr: require("../images/smartphone/joko-app-screenshot-home-5-fr.webp"),
  en: require("../images/smartphone/joko-app-screenshot-home-5-en.webp"),
};
const Smartphone5WebP2xSrc: LocalizedAsset = {
  fr: require("../images/smartphone/joko-app-screenshot-home-5-fr@2x.webp"),
  en: require("../images/smartphone/joko-app-screenshot-home-5-en@2x.webp"),
};

const Wrapper = styled.section`
  position: relative;
  //position: sticky;
  //top: 0;
  //height: 100vh;
  //overflow: scroll;
  //scroll-snap-type: mandatory;
  //scroll-snap-points-y: repeat(100vh);
  //scroll-snap-type: y mandatory;
`;

interface SlidePropsModel {
  color: string;
}

const Slide = styled.article<SlidePropsModel>`
  //scroll-snap-align: start;
  position: sticky;
  top: 0;
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  box-sizing: border-box;
  text-align: center;
  background-color: ${theme.palette.common.white};
  //for dev
  ${(props: any) => {
    /*if (props.color) {
      return `background-color: ${props.color}`;
    }*/
  }};
`;

const SlideHalfCenteredRow = styled(HalfCenteredRow)`
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding-top: 6.25em;
  padding-bottom: 3.125em;

  ${headerGreaterThanMDMediaQuery} {
    padding-top: ${3.125 + 4.75}em; // 76 == height of top bar
  }
`;

const SmartphoneWrapper = styled(CenteredRow)`
  position: relative;
  height: 66.6666vh;
  height: calc(66.6666vh + 6.25em);
  margin-bottom: -6.25em;
  overflow: hidden;
`;

const Smartphone = styled.img`
  width: 31.25em;
  height: auto;
  max-width: 100%;
`;

const options = {
  renderNode: {
    [BLOCKS.PARAGRAPH]: (_node, children) => <p className="text">{children}</p>,
  },
};

export function Presentation(props: { items: PresentationArgumentModel[] }) {
  const intl = useIntl();
  const locale = intl.locale as Locale;
  const items = React.useMemo(
    () => props.items.sort((a, b) => a.order - b.order),
    [props.items]
  );
  const smartphones = {
    0: [
      localizeAsset(locale, Smartphone1Src),
      localizeAsset(locale, Smartphone12xSrc),
      localizeAsset(locale, Smartphone1WebPSrc),
      localizeAsset(locale, Smartphone1WebP2xSrc),
    ],
    1: [
      localizeAsset(locale, Smartphone2Src),
      localizeAsset(locale, Smartphone22xSrc),
      localizeAsset(locale, Smartphone2WebPSrc),
      localizeAsset(locale, Smartphone2WebP2xSrc),
    ],
    2: [
      localizeAsset(locale, Smartphone3Src),
      localizeAsset(locale, Smartphone32xSrc),
      localizeAsset(locale, Smartphone3WebPSrc),
      localizeAsset(locale, Smartphone3WebP2xSrc),
    ],
    3: [
      localizeAsset(locale, Smartphone4Src),
      localizeAsset(locale, Smartphone42xSrc),
      localizeAsset(locale, Smartphone4WebPSrc),
      localizeAsset(locale, Smartphone4WebP2xSrc),
    ],
    4: [
      localizeAsset(locale, Smartphone5Src),
      localizeAsset(locale, Smartphone52xSrc),
      localizeAsset(locale, Smartphone5WebPSrc),
      localizeAsset(locale, Smartphone5WebP2xSrc),
    ],
  };
  return (
    <Wrapper>
      {items.map(({ order, intro, text }: PresentationArgumentModel) => (
        <Slide
          key={`presentation-item-${order}`}
          color={"#" + ((Math.random() * 0xffffff) << 0).toString(16)}
        >
          <SlideHalfCenteredRow>
            <div>
              <h3 className="heading">{intro}</h3>
              {text?.raw
                ? documentToReactComponents(JSON.parse(text.raw), options)
                : ""}
            </div>
          </SlideHalfCenteredRow>
          <SmartphoneWrapper>
            <picture>
              <source
                srcSet={`${smartphones[order][2]} 500w, ${smartphones[order][3]} 1000w`}
                type="image/webp"
              />
              <source
                srcSet={`${smartphones[order][0]} 500w, ${smartphones[order][1]} 1000w`}
                type="image/png"
              />
              <Smartphone
                src={smartphones[order][0]}
                width="500"
                alt={intl.formatMessage({ id: "home.introImageAltText" })}
              />
            </picture>
          </SmartphoneWrapper>
        </Slide>
      ))}
    </Wrapper>
  );
}
