import React from "react";
import { isIE } from "react-device-detect";
import { Helmet } from "react-helmet";
import { graphql } from "gatsby";
import { useIntl } from "gatsby-plugin-intl";
import styled, { ThemeProvider, createGlobalStyle } from "styled-components";
import { Reset } from "styled-reset";

import { theme } from "../theme/theme";
import FontFaces from "../theme/fonts/font-faces";
import GlobalStyle from "../theme/global";
import {
  ContentfulBrand,
  ContentfulHome,
  ContentfulPresentationArgument,
} from "../utils/contentful";
import { BrandModel } from "../models/brand.model";
import { Header, headerGreaterThanMDMediaQuery } from "../components/header";
import { Intro } from "../components/intro";
import { OpinionsGallery } from "../components/opinions-gallery";
import { BrandsGallery } from "../components/brands-gallery";
import { Overstatement } from "../components/overstatement";
import { Presentation } from "../components/presentation";
import { InThePress } from "../components/in-the-press";
import {
  AnimatedMarquee,
  CallToAction,
  CallToActionButtons,
} from "../components/call-to-action";
import { Footer } from "../components/footer";
import { OutdatedBrowserAlert } from "../components/outdated-browser-alert";
import { MinimalHeader } from "../components/minimal-header";

export const ENGLISH_HOMEPAGE = "https://www.joko.com/en";
export const FRENCH_HOMEPAGE = "https://www.joko.com/fr";

const IndexPage = ({ data }) => {
  const intl = useIntl();
  useFontLoader();
  const [homePage, homeBrands, homePresentationArguments] =
    useFormatHomeQueryResult(intl.locale, data);
  return (
    <ThemeProvider theme={theme}>
      <Reset />
      <ExtraGlobalStyle />
      <GlobalStyle />
      <main>
        <Helmet>
          <title>{intl.formatMessage({ id: "home.pageTitle" })}</title>
          <style type="text/css">{FontFaces}</style>
          <link rel="alternate" hrefLang="en-gb" href={ENGLISH_HOMEPAGE} />
          <link rel="alternate" hrefLang="en-us" href={ENGLISH_HOMEPAGE} />
          <link rel="alternate" hrefLang="en" href={ENGLISH_HOMEPAGE} />
          <link rel="alternate" hrefLang="fr" href={FRENCH_HOMEPAGE} />
          <link rel="alternate" hrefLang="x-default" href={ENGLISH_HOMEPAGE} />
          <meta
            name="description"
            content={
              intl.locale === "en"
                ? "What if all your purchases became cheaper, without any effort? Coupons, price tracking, cash back"
                : "Boostez votre pouvoir d'achat ! Et si tout devenait moins cher, sans aucun effort ? Codes promo, suivi des prix, cashback, paiement en plusieurs fois"
            }
            key="description"
          />
        </Helmet>
        {isIE ? (
          <>
            <MinimalHeader isHome />
            <OutdatedBrowserAlert />
          </>
        ) : (
          <>
            <Header isHome />
            <Intro
              taglineHtml={homePage.tagline}
              indicationRaw={homePage.indication.raw}
            />
            <CurveClippingMasksWrapper>
              <OpinionsGallery
                titleHTML={homePage.opinionsTitle}
                introRaw={homePage.opinionsIntro.raw}
              />
              <BrandsGallery
                titleHTML={homePage.brandsTitle}
                introRaw={homePage.brandsIntro.raw}
                items={convertBrandsData(homeBrands)}
              />
              <Overstatement textHTML={homePage.overstatement} />
            </CurveClippingMasksWrapper>
            <Presentation items={homePresentationArguments} />
            <CurveClippingMasksWrapper>
              <ColoredWrapper
                verticalSpacing={intl.locale === "en" ? "3em" : undefined}
                backgroundColor={
                  intl.locale === "en" ? theme.palette.common.black : undefined
                }
                textColor={
                  intl.locale === "en" ? theme.palette.common.white : undefined
                }
              >
                {intl.locale === "en" ? (
                  <AnimatedMarquee
                    textHTML={homePage.callToAction}
                    repetitions={
                      homePage.repetitionsOfCallToActionInsideMarquee
                    }
                  />
                ) : (
                  <InThePress introHTML={homePage.pressIntro} />
                )}
              </ColoredWrapper>
              {intl.locale === "en" ? (
                <CallToActionButtonsWrapper>
                  <CallToActionButtons />
                </CallToActionButtonsWrapper>
              ) : (
                <CallToAction
                  textHTML={homePage.callToAction}
                  repetitions={homePage.repetitionsOfCallToActionInsideMarquee}
                />
              )}
            </CurveClippingMasksWrapper>
          </>
        )}
        <Footer />
      </main>
    </ThemeProvider>
  );
};

export default IndexPage;

// Trick to limit the flash while the font is loading
function useFontLoader() {
  React.useEffect(() => {
    if (isIE || !("fonts" in document) || sessionStorage.fontsLoaded) {
      document.documentElement.classList.add("visible");
    } else {
      Promise.all([
        document.fonts.load("800 4.5em Ambit"),
        document.fonts.load("600 1.25em Ambit"),
        document.fonts.load("400 1em Ambit"),
      ]).then(() => {
        setTimeout(function () {
          document.documentElement.classList.add("visible");
        }, 100);
      });
    }
  }, []);
}

export const homeQuery = graphql`
  query HomeQuery($language: String) {
    allContentfulHome(filter: { node_locale: { eq: $language } }) {
      nodes {
        node_locale
        tagline
        indication {
          raw
        }
        opinionsTitle
        opinionsIntro {
          raw
        }
        brandsTitle
        brandsIntro {
          raw
        }
        overstatement
        pressIntro
        callToAction
        repetitionsOfCallToActionInsideMarquee
      }
    }
    contentfulBrands(node_locale: { eq: $language }) {
      items {
        internal {
          content
        }
      }
    }
    allContentfulPresentationArgument(
      filter: { node_locale: { eq: $language } }
    ) {
      nodes {
        node_locale
        order
        intro
        text {
          raw
        }
        metadata {
          tags {
            contentful_id
          }
        }
      }
    }
  }
`;

interface HomeDataQueryResponse {
  allContentfulHome: {
    nodes: ContentfulHome[];
  };
  contentfulBrands: {
    items: ContentfulBrand[];
  };
  allContentfulPresentationArgument: {
    nodes: ContentfulPresentationArgument[];
  };
}

function useFormatHomeQueryResult(
  locale: string,
  queryResult: HomeDataQueryResponse
) {
  const homePage: ContentfulHome = queryResult.allContentfulHome.nodes[0];
  const homeBrands: ContentfulBrand[] = queryResult.contentfulBrands.items;
  const homePresentationArguments: ContentfulPresentationArgument[] =
    React.useMemo(
      () =>
        queryResult.allContentfulPresentationArgument.nodes.filter(
          (presentationArgument: ContentfulPresentationArgument) =>
            presentationArgument.metadata.tags.filter(
              (tag) => locale === "en" && tag.contentful_id === "hiddenEn"
            ).length === 0
        ),
      [queryResult.allContentfulPresentationArgument.nodes]
    );
  return [homePage, homeBrands, homePresentationArguments];
}

function convertBrandsData(brands: ContentfulBrand[]): BrandModel[] {
  return brands.map((element: ContentfulBrand) =>
    JSON.parse(element.internal.content)
  );
}

const ExtraGlobalStyle = createGlobalStyle`
  html:not(.visible) {
    visibility: hidden;
  }
`;

const CurveClippingMasksWrapper = styled.div`
  overflow: hidden;
`;

const ColoredWrapper = styled.section<{
  verticalSpacing?: string;
  backgroundColor?: string;
  textColor?: string;
}>`
  position: relative;
  width: 100%;
  padding: ${({ verticalSpacing }) => (verticalSpacing ?? "6.25em") + " 0"};
  margin-top: ${({ verticalSpacing }) => verticalSpacing ?? "6.25em"};
  margin-bottom: ${({ verticalSpacing }) => verticalSpacing ?? "6.25em"};
  background-color: ${({ backgroundColor }) =>
    backgroundColor || theme.palette.secondary.basic};
  color: ${({ textColor }) => textColor ?? theme.palette.common.white};
  text-align: center;

  &:before {
    content: "";
    display: block;
    position: absolute;
    bottom: 100%;
    left: 50%;
    height: ${({ verticalSpacing }) => verticalSpacing ?? "6.25em"};
    width: 59.375em;
    margin-bottom: -1px;
    transform: translate(-50%, 0);
    background-color: ${({ backgroundColor }) =>
      backgroundColor ?? theme.palette.secondary.basic};
    clip-path: url(#curve-inside);

    ${headerGreaterThanMDMediaQuery} {
      width: 100em;
    }

    @media screen and (min-width: 100em) {
      width: 100%;
    }
  }

  &:after {
    content: "";
    display: block;
    position: absolute;
    top: 100%;
    left: 50%;
    height: ${({ verticalSpacing }) => verticalSpacing ?? "6.25em"};
    width: 59.375em;
    margin-top: -1px;
    transform: translate(-50%, 0);
    background-color: ${({ backgroundColor }) =>
      backgroundColor ?? theme.palette.secondary.basic};
    clip-path: url(#curve-outside);

    ${headerGreaterThanMDMediaQuery} {
      width: 100em;
    }

    @media screen and (min-width: 100em) {
      width: 100%;
    }
  }
`;

const CallToActionButtonsWrapper = styled.section`
  padding-top: 3rem;
  padding-bottom: 6rem;
`;
