import React from "react";
import styled from "styled-components";
import { BrandModel } from "../../models/brand.model";
import { theme } from "../../theme/theme";
import { Marquee } from "./marquee";

const Wrapper = styled.div`
  overflow: hidden;
`;

const List = styled.ul`
  display: flex;
  flex-wrap: nowrap;

  & + & {
    margin-top: 0.5625em;
  }
`;

const ListItem = styled.li`
  margin: 0 0.5625em;
`;

const Brand = styled.p`
  white-space: nowrap;
  opacity: 0.2;
  transition: opacity 0.2s ease-in-out, color 0.2s ease-in-out;
  cursor: default;

  &:hover,
  &:active,
  &:focus {
    opacity: 1;
    color: ${theme.palette.primary.basic};
  }
`;

interface StateModel {
  itemsGroups: BrandModel[][];
}

interface PropsModel {
  items: BrandModel[];
  className: string;
}

export class BrandsList extends React.Component<PropsModel, StateModel> {
  constructor(props: PropsModel) {
    super(props);

    this.state = {
      itemsGroups: this.mixedSplittedItems(props.items),
    };
  }

  mixedSplittedItems(items: BrandModel[]): BrandModel[][] {
    items.sort((_a: BrandModel, _b: BrandModel) => 0.5 - Math.random());

    const chunkSize: number = Math.round(items.length / 4);

    const lists = items
      .map((_item: BrandModel, index: number) => {
        return index % chunkSize === 0
          ? items.slice(index, index + chunkSize)
          : null;
      })
      .filter((group: BrandModel[]) => {
        return group;
      });

    if (lists.length > 4) lists.pop();

    return lists;
  }

  componentWillReceiveProps(newProps) {
    if (typeof newProps.items !== "undefined") {
      this.setState({
        itemsGroups: this.mixedSplittedItems(newProps.items),
      });
    }
  }

  render() {
    return (
      <Wrapper className={this.props.className}>
        {this.state.itemsGroups.map((itemsGroup: BrandModel[], i: number) => (
          <Marquee
            key={"BRANDS-" + i}
            prefix={"BRANDS-" + i}
            repetitions={5}
            duration={"50s"}
          >
            {[...Array(5)].map((_item: any, index: number) => (
              <List key={i + index}>
                {itemsGroup.map((item: BrandModel, j: number) => (
                  <ListItem key={i + j + index}>
                    <Brand className="big-heading">{item.name}</Brand>
                  </ListItem>
                ))}
              </List>
            ))}
          </Marquee>
        ))}
      </Wrapper>
    );
  }
}
