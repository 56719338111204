import React from "react";
import styled from "styled-components";

import { theme } from "../theme/theme";
import { HalfCenteredRow } from "./common/layout";

import LaTribuneLogoSvg from "../images/la-tribune-logo.inline.svg";
import LeMondeLogoSvg from "../images/le-monde-logo.inline.svg";
import MaddynessLogoSvg from "../images/maddyness-logo.inline.svg";
import TF1LogoSvg from "../images/tf1-logo.inline.svg";
import LesEchosLogoSvg from "../images/les-echos-logo.inline.svg";
import BFMLogoSvg from "../images/bfm-logo.inline.svg";
import { Marquee, MarqueeWrapper } from "./common/marquee";
import { headerGreaterThanMDMediaQuery } from "./header";

const Wrapper = styled.section`
  svg {
    fill: ${theme.palette.common.white};
  }
`;

const PressListMarqueeWrapper = styled(MarqueeWrapper)`
  margin-top: 6.25em;
`;

const PressList = styled.ul`
  line-height: 0;
`;

const PressListItem = styled.li`
  position: relative;
  display: inline-block;
  vertical-align: middle;
  padding: 0 ${3.125 + 1.875 / 2}em;

  &:before {
    content: "";
    position: absolute;
    top: 50%;
    left: 0;
    transform: translate(-50%, -50%);
    display: block;
    width: 1.875em;
    height: 3.125em;
    background-image: url("data:image/svg+xml;charset=UTF-8,%3csvg width='30' height='50' viewBox='0 0 30 50' xmlns='http://www.w3.org/2000/svg'%3e%3cpath fill='${theme
      .palette.secondary
      .darker}' d='M16.2,19.6l3.3-17.3c0.1-0.8-1-1.1-1.6-0.5C11.1,9.9,3.7,22.3,2,32.1c-0.1,0.5,0.4,0.9,0.9,0.7l10.8-2.4 l-3.3,17.3c-0.1,0.8,1,1.1,1.6,0.5c6.8-8,14.2-20.4,15.9-30.2c0.1-0.5-0.4-0.9-0.9-0.7L16.2,19.6z' /%3e%3c/svg%3e");
    background-size: 100%;
    background-position: 50%;
    background-repeat: no-repeat;
  }
`;

type PropsModel = {
  introHTML: string;
};

export const InThePress = ({ introHTML }: PropsModel) => {
  return (
    <Wrapper>
      <HalfCenteredRow>
        <h2 className="emphasized-text">{introHTML}</h2>
      </HalfCenteredRow>

      <PressListMarqueeWrapper>
        <Marquee prefix={"PRESS"} repetitions={5} duration={"20s"}>
          {[...Array(5)].map((_item: any, index: number) => (
            <PressList key={index}>
              <PressListItem>
                <LaTribuneLogoSvg />
              </PressListItem>
              <PressListItem>
                <LeMondeLogoSvg />
              </PressListItem>
              <PressListItem>
                <MaddynessLogoSvg />
              </PressListItem>
              <PressListItem>
                <TF1LogoSvg />
              </PressListItem>
              <PressListItem>
                <LesEchosLogoSvg />
              </PressListItem>
              <PressListItem>
                <BFMLogoSvg />
              </PressListItem>
            </PressList>
          ))}
        </Marquee>
      </PressListMarqueeWrapper>
    </Wrapper>
  );
};
