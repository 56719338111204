import React from "react";
import styled from "styled-components";
import { useIntl } from "gatsby-plugin-intl";

import { HalfCenteredRow } from "./common/layout";
import { GooglePlayButton } from "../components/common/googleplay-button";
import { AppleStoreButton } from "../components/common/applestore-button";
import { BrowserExtensionButton } from "../components/common/browser-extension-button";

export const OutdatedBrowserAlert = () => {
  const intl = useIntl();
  return (
    <Wrapper>
      <HalfCenteredRow>
        <Heading className="heading">
          {intl.formatMessage({ id: "home.footer.outdatedBrowserAlert.title" })}
        </Heading>
        <IntroWrapper>
          <ButtonsList>
            <ButtonsListItem>
              <GooglePlayButton />
            </ButtonsListItem>
            <ButtonsListItem>
              <AppleStoreButton />
            </ButtonsListItem>
            <ButtonsListItem>
              <BrowserExtensionButton />
            </ButtonsListItem>
          </ButtonsList>
          <p className="emphasized-text">
            {intl.formatMessage({
              id: "home.footer.outdatedBrowserAlert.subtitle",
            })}
          </p>
        </IntroWrapper>
      </HalfCenteredRow>
    </Wrapper>
  );
};

const Wrapper = styled.section`
  min-height: 100vh;
  box-sizing: border-box;
  padding: 6.25em 0;
`;

const Heading = styled.h1`
  text-align: center;
  margin-bottom: 1em;
`;

const IntroWrapper = styled.div`
  text-align: center;
`;

const ButtonsList = styled.ul`
  margin: -0.8125em;
`;

const ButtonsListItem = styled.li`
  display: inline-block;
  vertical-align: middle;
  margin: 0.8125em;
`;
