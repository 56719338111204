import React, { ReactElement } from "react";
import styled from "styled-components";
import { OpinionModel } from "../../models/opinion.model";

import Star from "../../images/star.inline.svg";
import FacebookLogoSvg from "../../images/facebook-logo.inline.svg";
import AppleLogoSvg from "../../images/apple-logo.inline.svg";
import GooglePlayLogoSvg from "../../images/google-play-logo.inline.svg";

import { theme } from "../../theme/theme";

const Wrapper = styled.article``;

const Footer = styled.footer`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  margin-top: 1em;
`;

const StarsList = styled.ul`
  margin: -0.125em;
`;

const StarListItem = styled.li`
  display: inline;
  margin: 0.125em;

  svg {
    fill: ${theme.palette.primary.basic};
  }
`;

const Transmitter = styled.div`
  display: flex;
  align-items: center;
`;

const StyledGooglePlayLogoSvg = styled(GooglePlayLogoSvg)`
  margin-right: 0.5em;
`;

const StyledAppleLogoSvg = styled(AppleLogoSvg)`
  margin-right: 0.5em;
`;

const StyledFacebookLogoSvg = styled(FacebookLogoSvg)`
  margin-right: 0.5em;
`;

interface PropsModel {
  className: string;
  data: OpinionModel;
}

export class OpinionArticle extends React.Component<PropsModel, any> {
  constructor(props: PropsModel) {
    super(props);
  }

  stars(): ReactElement<any, any> {
    if (this.props.data.score) {
      return (
        <StarsList>
          {Array.from(Array(this.props.data.score | 0), (_element, i) => {
            return (
              <StarListItem key={i}>
                <Star className="star" />
              </StarListItem>
            );
          })}
        </StarsList>
      );
    }
  }

  platformLogo(): ReactElement<any, any> {
    switch (this.props.data.platform) {
      case "Play Store": {
        return <StyledGooglePlayLogoSvg />;
      }
      case "App Store": {
        return <StyledAppleLogoSvg />;
      }
      case "Facebook": {
        return <StyledFacebookLogoSvg />;
      }
    }
  }

  render() {
    return (
      <Wrapper className={this.props.className}>
        <p className="text">{this.props.data.description}</p>
        <Footer>
          <Transmitter>
            {this.platformLogo()}
            <p className="very-small-heading">{this.props.data.username}</p>
          </Transmitter>
          {this.stars()}
        </Footer>
      </Wrapper>
    );
  }
}
