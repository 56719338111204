import React from "react";
import { Link } from "gatsby";
import styled from "styled-components";

import { scrollToTop } from "../utils/scroll-to-top";

import LogoSrc from "../images/joko.svg";
import UserSvg from "../images/user.inline.svg";

import { theme } from "../theme/theme";

import { ConditionalWrapper } from "./common/conditional-wrapper";
import { CenteredRow } from "./common/layout";

import { createContext } from "react";
import { LinkAsButton } from "./common/button";
import { headerGreaterThanMDMediaQuery } from "./header";
export const HeaderMenuOpenedContext = createContext(false);

const StyledCenteredRow = styled(CenteredRow)`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const Wrapper = styled.header`
  position: relative;
  z-index: 2;
  top: 0;
  left: 0;
  width: 100%;
  padding: 3.125em 0 0.625em;
`;

const LogoLink = styled(Link)`
  position: relative;
  z-index: 1;
  display: block;
  line-height: 0;
`;

const Logo = styled.img`
  height: 1.875em;

  ${headerGreaterThanMDMediaQuery} {
    height: 3.5em;
  }
`;

const StyledUserSvg = styled(UserSvg)`
  stroke: ${theme.palette.common.black};
`;

interface PropsModel {
  isHome?: boolean;
}

export class MinimalHeader extends React.Component<PropsModel, any> {
  constructor(props: any) {
    super(props);
  }

  render() {
    return (
      <Wrapper>
        <StyledCenteredRow>
          <ConditionalWrapper
            condition={this.props.isHome}
            wrapper={(children) => <h1>{children}</h1>}
          >
            <LogoLink
              to="/"
              onClick={scrollToTop}
              aria-label="Accueil Hello Joko"
            >
              <Logo src={LogoSrc} alt="Joko" />
            </LogoLink>
          </ConditionalWrapper>
          <LinkAsButton
            bigger
            openwork
            rounded
            href={process.env.WEBAPP_SIGN_IN_LINK}
            target="_blank"
            aria-label="Connexion"
            as="a"
          >
            <StyledUserSvg />
          </LinkAsButton>
        </StyledCenteredRow>
      </Wrapper>
    );
  }
}
