import React from "react";
import styled from "styled-components";

import { detectOs, OS } from "../utils/detect-os";
import { HalfCenteredRow } from "./common/layout";
import { GooglePlayButton } from "../components/common/googleplay-button";
import { AppleStoreButton } from "../components/common/applestore-button";
import { BrowserExtensionButton } from "../components/common/browser-extension-button";
import { Marquee, MarqueeWrapper } from "./common/marquee";

const Wrapper = styled.section`
  padding: 6.25em 0;
`;

const ButtonsList = styled.ul`
  margin: ${2.875 - 0.8125}em -0.8125em -0.8125em;
`;

const ButtonsListItem = styled.li`
  display: inline-block;
  vertical-align: middle;
  margin: 0.8125em;
`;

type PropsModel = {
  textHTML: string;
  repetitions: number;
};

export function AnimatedMarquee({ textHTML, repetitions }: PropsModel) {
  return (
    <MarqueeWrapper>
      <Marquee
        className="big-heading"
        prefix={"CTA"}
        repetitions={repetitions}
        duration={"5s"}
      >
        <h2
          dangerouslySetInnerHTML={{
            __html: textHTML + "&nbsp;",
          }}
        />
        {[...Array(repetitions - 1)].map((_item: any, index: number) => (
          <span
            key={index}
            dangerouslySetInnerHTML={{
              __html: textHTML + "&nbsp;",
            }}
          />
        ))}
      </Marquee>
    </MarqueeWrapper>
  );
}

export function CallToActionButtons() {
  const os = detectOs();
  return (
    <HalfCenteredRow>
      <ButtonsList>
        {os === OS.Desktop || os === OS.Android ? (
          <ButtonsListItem>
            <GooglePlayButton />
          </ButtonsListItem>
        ) : null}

        {os === OS.Desktop || os === OS.IOs ? (
          <ButtonsListItem>
            <AppleStoreButton />
          </ButtonsListItem>
        ) : null}

        {os !== OS.Android && os !== OS.IOs ? (
          <ButtonsListItem>
            <BrowserExtensionButton />
          </ButtonsListItem>
        ) : null}
      </ButtonsList>
    </HalfCenteredRow>
  );
}

export function CallToAction(props: PropsModel) {
  return (
    <Wrapper>
      <AnimatedMarquee {...props} />
      <CallToActionButtons />
    </Wrapper>
  );
}
