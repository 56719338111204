import { useState, useEffect } from "react";
import { useIntl } from "gatsby-plugin-intl";
import { OpinionModel } from "../models/opinion.model";
import { fetchVisibleContents } from "../utils/fetch-visible-contents";

export function useOpinions(): OpinionModel[] {
  const intl = useIntl();
  const [reviews, setReviews] = useState<OpinionModel[]>([]);
  const fetchReviews = async () => {
    const contents = await fetchVisibleContents<OpinionModel>(
      intl.locale === "en"
        ? "user-review-items-en.json"
        : "user-review-items.json",
      true
    );
    // The UI looks bad if we have an odd number of reviews
    setReviews(contents.length % 2 ? [...contents, contents[0]] : contents);
  };
  useEffect(() => {
    fetchReviews();
  }, []);
  return reviews;
}
