import styled from "styled-components";

export const MarqueeWrapper = styled.div`
  width: 100%;
  overflow: hidden;
`;

type MarqueePropsModel = {
  repetitions: number;
  duration: string;
  prefix: string;
};

export const Marquee = styled.div<MarqueePropsModel>`
  ${(props) => {
    return `
      @keyframes ${props.prefix}-MARQUEE {
        0% {
          transform: translate3d(-${100 / props.repetitions}%, 0, 0);
        }
      
        100% {
          transform: translate3d(-${(100 / props.repetitions) * 2}%, 0, 0);
        }
      }
    `;
  }}

  position: relative;
  display: flex;
  width: fit-content;
  will-change: transform;

  ${(props) => {
    return `
      transform: translate3d(-${100 / props.repetitions}%, 0, 0);
      animation: ${props.prefix}-MARQUEE ${props.duration} linear infinite;
    `;
  }}

  animation-play-state: running;
  white-space: nowrap;
`;
