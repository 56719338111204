import React from "react";
import { BLOCKS } from "@contentful/rich-text-types";
import { documentToReactComponents } from "@contentful/rich-text-react-renderer";
import styled, { css } from "styled-components";
import { useOpinions } from "../api/opinions";
import { OpinionModel } from "../models/opinion.model";

import { theme } from "../theme/theme";
import { OpinionArticle } from "./common/opinion-article";
import { CenteredRow, HalfCenteredRow } from "./common/layout";
import { ltMDMediaQuery } from "../theme/global";
import { headerGreaterThanMDMediaQuery } from "./header";

const StyledOpinion = styled(OpinionArticle)``;

const Wrapper = styled.section`
  position: relative;
  width: 100%;
  padding: 12.5em 0 3.125em;
  margin-top: 6.25em;
  background-color: ${theme.palette.common.black};
  color: ${theme.palette.common.white};

  ${ltMDMediaQuery} {
    mix-blend-mode: color;
  }

  &:before {
    content: "";
    display: block;
    position: absolute;
    bottom: 100%;
    left: 50%;
    transform: translate(-50%, 0);
    height: 6.25em;
    width: 59.375em;
    margin-bottom: -1px;
    background-color: ${theme.palette.common.black};
    clip-path: url(#curve-inside);

    ${headerGreaterThanMDMediaQuery} {
      width: 100em;
    }

    @media screen and (min-width: 100em) {
      width: 100%;
    }
  }
`;

const Title = styled.h2`
  text-align: center;
`;

const IntroWrapper = styled.div`
  margin-top: 3.125em;
  margin-bottom: 6.25em;
  text-align: center;
`;

const ListsWrapper = styled.div`
  overflow: hidden;
`;

const List = styled.ul`
  display: flex;
  flex-wrap: nowrap;
  position: relative;
  width: fit-content;
  margin: -0.5625em 0 -0.5625em 50%;
  transform: translateX(-50%);

  & + & {
    margin-top: 0.5625em;
  }

  ${StyledOpinion} {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    width: 18.75em;
    min-height: 100%;
    box-sizing: border-box;
    padding: 1.875em 2.5em;
    border: 2px solid ${theme.palette.common.white};
    border-radius: 0.5em;

    svg:not(.star) {
      fill: ${theme.palette.common.white};
    }
  }
`;

interface ListItemPropsModel {
  index: number;
  length: number;
  reversedAnimationDirection: boolean;
}

const ListItem = styled.li<ListItemPropsModel>`
  margin: 0.5625em;
  transform: translate3d(0, 0, 0);
  will-change: transform;

  ${(props) => {
    let styles: css = `
      @keyframes OPINION-SCROLLING-${props.index} {
        0% {
          transform: translate3d(-${19.875 * props.index}em, 0, 0);
        }
      
        100% {
          transform: translate3d(${
            19.875 * (props.length - props.index)
          }em, 0, 0);
        }
      }
    
      animation: OPINION-SCROLLING-${props.index};  
      animation-duration: 200s;
      animation-timing-function: linear;
      animation-iteration-count: infinite;
      animation-play-state: running;
      animation-delay: -${(200 / props.length) * props.index}s;
    `;

    if (props.reversedAnimationDirection)
      styles += "animation-direction: reverse;";
    return styles;
  }};
`;

const options = {
  renderNode: {
    [BLOCKS.PARAGRAPH]: (_node, children) => (
      <p className="emphasized-text">{children}</p>
    ),
  },
};

type PropsModel = {
  titleHTML: string;
  introRaw: string;
};

export const OpinionsGallery = ({ titleHTML, introRaw }: PropsModel) => {
  const opinions = useOpinions();
  let firstHalfOpinions: OpinionModel[] = [];
  let secondHalfOpinions: OpinionModel[] = [];
  if (opinions.length > 0) {
    const halfLength: number = Math.ceil(opinions.length / 2);
    firstHalfOpinions = opinions.splice(0, halfLength);
    secondHalfOpinions = opinions.splice(-halfLength);
  }
  return (
    <Wrapper>
      <CenteredRow>
        <Title
          className="big-heading"
          dangerouslySetInnerHTML={{
            __html: titleHTML,
          }}
        ></Title>
      </CenteredRow>
      <HalfCenteredRow>
        <IntroWrapper>
          {introRaw
            ? documentToReactComponents(JSON.parse(introRaw), options)
            : ""}
        </IntroWrapper>
      </HalfCenteredRow>
      <ListsWrapper>
        <List animationDirection="reverse">
          {firstHalfOpinions.map((opinion: OpinionModel, i: number) => (
            <ListItem
              key={i}
              index={i}
              length={firstHalfOpinions.length}
              reversedAnimationDirection={true}
            >
              <StyledOpinion data={opinion} />
            </ListItem>
          ))}
        </List>

        <List>
          {secondHalfOpinions.map((opinion: OpinionModel, i: number) => (
            <ListItem key={i} index={i} length={secondHalfOpinions.length}>
              <StyledOpinion data={opinion} />
            </ListItem>
          ))}
        </List>
      </ListsWrapper>
    </Wrapper>
  );
};
