import React from "react";
import { BLOCKS } from "@contentful/rich-text-types";
import { documentToReactComponents } from "@contentful/rich-text-react-renderer";
import styled from "styled-components";
import { BrandModel } from "../models/brand.model";

import { HalfCenteredRow, CenteredRow } from "./common/layout";
import { BrandsList } from "./common/brands-list";

import { theme } from "../theme/theme";
import { headerGreaterThanMDMediaQuery } from "./header";

const StyledBrandsList = styled(BrandsList)``;

const Wrapper = styled.section`
  position: relative;
  width: 100%;
  padding: 6.25em 0 3.125em;
  margin-bottom: 6.25em;
  background-color: ${theme.palette.common.black};
  color: ${theme.palette.common.white};

  &:after {
    content: "";
    display: block;
    position: absolute;
    top: 100%;
    left: 50%;
    transform: translate(-50%, 0);
    height: 6.25em;
    width: 59.375em;
    margin-top: -1px;
    background-color: ${theme.palette.common.black};
    clip-path: url(#curve-outside);

    ${headerGreaterThanMDMediaQuery} {
      width: 100em;
    }

    @media screen and (min-width: 100em) {
      width: 100%;
    }
  }

  ${StyledBrandsList} {
    margin-top: 3.125em;
  }
`;

const Title = styled.h2`
  text-align: center;
`;

const IntroWrapper = styled.div`
  margin-top: 3.125em;
  text-align: center;
`;

const options = {
  renderNode: {
    [BLOCKS.PARAGRAPH]: (_node, children) => (
      <p className="emphasized-text">{children}</p>
    ),
  },
};

interface StateModel {
  activatedFilters: string[];
  items: BrandModel[];
}

interface PropsModel {
  titleHTML: string;
  introRaw: string;
  items: BrandModel[];
}

export class BrandsGallery extends React.Component<PropsModel, StateModel> {
  constructor(props: PropsModel) {
    super(props);

    this.state = {
      items: props.items,
      activatedFilters: [],
    };
  }

  render() {
    return (
      <Wrapper>
        <CenteredRow>
          <Title
            className="big-heading"
            dangerouslySetInnerHTML={{
              __html: this.props.titleHTML,
            }}
          ></Title>
        </CenteredRow>
        <HalfCenteredRow>
          <IntroWrapper>
            {this.props.introRaw
              ? documentToReactComponents(
                  JSON.parse(this.props.introRaw),
                  options
                )
              : ""}
          </IntroWrapper>
        </HalfCenteredRow>

        <StyledBrandsList items={this.state.items} />
      </Wrapper>
    );
  }
}
